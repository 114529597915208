import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('lms-card',{staticClass:"team-list-item d-flex flex-column flex-grow",attrs:{"hover":""}},[_c('lms-card-title',{attrs:{"small":""},on:{"click":function($event){return _vm.$emit('edit', _vm.team._id)}}},[_vm._v(" "+_vm._s(_vm.team.name)+" ")]),_c(VCardText,{staticClass:"team-list-item__content grow",on:{"click":function($event){return _vm.$emit('edit', _vm.team._id)}}},[_c(VRow,[_c(VCol,[_c(VChip,{staticStyle:{"margin-right":"10px"},attrs:{"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.team.members.length)+" "+_vm._s(_vm.$tc('labels.teamMembersCount', _vm.team.members.length))+" ")]),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.$tc('Masters', _vm.team.relations.leaders.length))+":"),_c('br'),_c('div',[_vm._v(" "+_vm._s(_vm.currentMasters(_vm.team.masters))+" ")]),_c('br'),_c('div',{},[_vm._v(" "+_vm._s(_vm.$tc('labels.teamLeader', _vm.team.relations.leaders.length))+":"),_c('br'),_vm._l((_vm.team.relations.leaders),function(item,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(item.name)+", ")])})],2)],1)],1)],1),_c('div',{directives:[{name:"allowed",rawName:"v-allowed",value:([_vm.userTypes.ADMIN, _vm.userTypes.MASTER]),expression:"[userTypes.ADMIN, userTypes.MASTER]"}],staticClass:"admin-actions"},[_c(VCardActions,{staticClass:"justify-space-between"},[_c('div',[_c('team-status',{attrs:{"status":_vm.team.status},on:{"change":(status) => _vm.$emit('changeStatus', _vm.team._id, status)}})],1),_c('div',{staticClass:"btns"},[_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('edit', _vm.team._id)}}},[_c(VIcon,[_vm._v("mdi-pencil-outline")])],1),_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('remove', _vm.team._id)}}},[_c(VIcon,[_vm._v("mdi-delete-outline")])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }