<template>
  <lms-card class="team-list-item d-flex flex-column flex-grow" hover>
    <lms-card-title small @click="$emit('edit', team._id)">
      {{ team.name }}
    </lms-card-title>

    <v-card-text
      class="team-list-item__content grow"
      @click="$emit('edit', team._id)">
      <v-row>
        <v-col>
          <v-chip style="margin-right: 10px" dark small>
            {{ team.members.length }}
            {{ $tc('labels.teamMembersCount', team.members.length) }} </v-chip
          ><br />
          <br />
          {{ $tc('Masters', team.relations.leaders.length) }}:<br />
          <div>
            {{ currentMasters(team.masters) }}
            <!-- <span v-for="(item, index) in team.masters" :key="index">
              {{ item.title }},
            </span> -->
          </div>
          <br />
          <div class="">
            {{ $tc('labels.teamLeader', team.relations.leaders.length) }}:<br />
            <span v-for="(item, index) in team.relations.leaders" :key="index">
              {{ item.name }},
            </span>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <div v-allowed="[userTypes.ADMIN, userTypes.MASTER]" class="admin-actions">
      <v-card-actions class="justify-space-between">
        <div>
          <team-status
            :status="team.status"
            @change="(status) => $emit('changeStatus', team._id, status)" />
        </div>
        <div class="btns">
          <a @click.stop="$emit('edit', team._id)">
            <v-icon>mdi-pencil-outline</v-icon>
          </a>
          <!-- <a @click.stop="$emit('report', team._id)">
                <v-icon>mdi-chart-box-outline</v-icon>
              </a> -->
          <a @click.stop="$emit('remove', team._id)">
            <v-icon>mdi-delete-outline</v-icon>
          </a>
        </div>
      </v-card-actions>
    </div>
  </lms-card>
</template>

<script>
import TeamStatus from './TeamStatus';
export default {
  components: {
    TeamStatus,
  },
  props: {
    team: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
    },
  },
  methods: {
    currentMasters(masters) {
      const cut = 7;
      if (masters.length > cut) {
        const _masters = masters
          .map((item) => item.title)
          .slice(0, cut)
          .join(', ');
        const plus = masters.length - cut;

        return `${_masters} +${plus}`;
      } else if (masters.length <= cut) {
        return masters.map((item) => item.title).join(', ');
      } else {
        return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base/variables';

.team-list-item {
  height: 100%;
  &__title {
    background-color: $box-selected;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 88;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    &::after {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      background: rgba(34, 32, 31, 0.3);
    }
    &__wrapper {
      position: relative;
      z-index: 2;
      padding: 20px 25px;
      background: rgba(12, 32, 51, 0.8);
      width: 100%;
      line-height: 1.2;
    }
    &__languages {
      display: flex;
      margin: 0 0 10px 0;
      &__item {
        color: #ffc007;
        margin: 0 5px 0 0;
        text-transform: uppercase;
        font-size: 12px;
      }
    }
    &__text {
      font-size: 16px;
      color: white;
    }
    &__progress {
      position: absolute;
      z-index: 2;
      top: 25px;
      left: 25px;
      font-size: 14px;
      &__value {
        color: white;
        font-weight: 900;
      }
    }
  }
  &__content {
    padding: 25px;
    cursor: pointer;
    &__text {
      font-size: 14px;
    }
  }
}
.admin-actions {
  padding: 24px 20px 15px 20px;
  border-top: 1px solid $global-bg;

  hr {
    margin-bottom: 10px;
    border-color: #eeeeee;
  }
  a {
    opacity: 0.6;
    margin: 0 0 0 15px;
  }
  i.v-icon.v-icon {
    color: $default-color;
    &:hover {
      color: $primary;
    }
  }
  .rating-title {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
  .btns {
    margin-top: -18px;
  }
}
</style>
